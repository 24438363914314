import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  HostBinding,
  ViewChild,
  Inject,
  PLATFORM_ID,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CurrentDictionaryItem, Language, LawyerSidebar, RefreshOnlineMessage } from '@app/shared/models';
import {
  AppSettingsService,
  BreakpointsService,
  DictionaryService,
  HttpService,
  SocketsService
} from '@app/shared/services';
import { ApiMethods, ContextMenuItem } from '@app/shared/types';
import { ReplaySubject, takeUntil, map } from 'rxjs';
import { ContextMenuModule } from '../../context-menu/context-menu.module';
import { SharedModule } from '@app/shared/shared.module';
import { LawyerListItemComponent } from './lawyer-list-item/lawyer-list-item.component';
import { SideBarRequest, SideBarResult } from '@app/shared/models/api';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ContextMenuComponent } from '@comp/context-menu/context-menu.component';

const HEIGHT_ITEM = 65;
export type LanguageItem = Pick<ContextMenuItem, 'action'> & CurrentDictionaryItem<Language>;

@Component({
  selector: 'ln-lawyer-list',
  templateUrl: './lawyer-list.component.html',
  styleUrls: ['./lawyer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, ContextMenuModule, LawyerListItemComponent]
})
export class LawyerListComponent implements OnInit, OnDestroy {
  online: LawyerSidebar[] = [];
  offline: LawyerSidebar[] = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  interObserver?: IntersectionObserver;
  showTopArrow = false;
  showBottomArrow = false;
  prevBreakpoint?: string;
  isMobile?: boolean;
  HEIGHT_ITEM = HEIGHT_ITEM;
  lastLoadedItems = 0;
  _expanded = false;
  menuLanguagesItems: LanguageItem[] = [];

  @ViewChild('listRef') listRef?: ElementRef;
  @ViewChild('languagesRef') languagesRef?: ContextMenuComponent;
  @ViewChild('languagesRefMob') languagesRefMob?: ContextMenuComponent;

  @HostBinding('class.expanded') @Input() set expanded(value: boolean) {
    this._expanded = value;
    this.cdr.detectChanges();
  }
  get expanded() {
    return this._expanded;
  }

  get languageCode() {
    return this.settings.settings.languageLawyersList?.iso.toLocaleUpperCase();
  }

  @Output() closeForm = new EventEmitter();
  @Output() expandedChange = new EventEmitter();
  @Output() mouseOver = new EventEmitter();
  @Output() mouseLeave = new EventEmitter();

  constructor(
    private httpService: HttpService,
    protected settings: AppSettingsService,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: any,
    private breakpoints: BreakpointsService,
    private socketsService: SocketsService,
    private translate: TranslateService,
    protected localize: LocalizeRouterService,
    private cdr: ChangeDetectorRef,
    private dictionaries: DictionaryService
  ) {}

  ngOnInit(): void {
    this.dictionaries
      .getLanguagesCurrent()
      .pipe(
        takeUntil(this.destroyed$),
        map((languages) => languages.map((lang) => ({ ...lang, action: this.onSelectLanguage(this) }) as LanguageItem))
      )
      .subscribe((languages) => {
        this.menuLanguagesItems = languages;
        this.cdr.detectChanges();
      });

    if (isPlatformBrowser(this.platformId)) {
      this.interObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const children = this.listRef?.nativeElement.children;
            if (entry.target === children[0]) this.showTopArrow = !entry.isIntersecting;
            else if (entry.target === children[children.length - 1]) this.showBottomArrow = !entry.isIntersecting;
            this.cdr.markForCheck();
          });
        },
        {
          threshold: 1,
          root: null
        }
      );

      this.breakpoints.mobile$?.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.loadList(this.isMobile);
      });

      this.socketsService.refresher
        ?.on<RefreshOnlineMessage>('refresh.online')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data) => {
          const oldList = data.online ? this.offline : this.online;
          const lawyerIdx = oldList?.findIndex((obj) => obj.id === data.id);

          if (lawyerIdx !== -1) {
            const lawyer = oldList[lawyerIdx];
            if (data.online) {
              lawyer.online = true;
              this.online.splice(0, 0, lawyer);
            } else {
              lawyer.online = false;
              this.offline.splice(0, 0, lawyer);
            }
            oldList?.splice(lawyerIdx, 1);
            if (this.listRef?.nativeElement.scrollTop === 0)
              setTimeout(() => this.listRef?.nativeElement.scroll(0, 0), 20);

            setTimeout(() => {
              this.connectObserver();
            }, 10);
            this.cdr.detectChanges();
          }
        });
    }
    // this.loadList(true);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    if (this.interObserver && this.listRef) this.interObserver?.unobserve(this.listRef.nativeElement);
  }

  onSelectLanguage(context: typeof this) {
    return (item: LanguageItem) => {
      this.settings.setLanguageForLawyersList({ title: item.name, ...item });
      context.loadList(this.isMobile, true);
    };
  }

  loadList(isMobile?: boolean, force = false) {
    const height = isPlatformBrowser(this.platformId) ? document.documentElement.clientHeight : 1000;
    const maxItems = Math.trunc((height - 205 - 118) / HEIGHT_ITEM);

    const limit = isMobile ? 10 : maxItems;

    if (!force && this.lastLoadedItems === limit) return;
    this.lastLoadedItems = limit;

    this.httpService
      .get<SideBarRequest, SideBarResult>(
        { method: ApiMethods.Sidebar },
        {
          limit,
          ...(this.settings.settings.languageLawyersList && {
            language: this.settings.settings.languageLawyersList.id
          })
        }
      )
      .subscribe((result) => {
        this.online = result.data.list.online;
        this.offline = result.data.list.offline;
        if (isPlatformBrowser(this.platformId)) setTimeout(() => this.connectObserver(), 10);
        this.cdr.detectChanges();
      });
  }

  connectObserver() {
    if (this.listRef?.nativeElement?.children[0]) {
      this.interObserver?.disconnect();
      this.interObserver?.observe(this.listRef?.nativeElement.children[0]);
      const children = this.listRef?.nativeElement.children;
      this.interObserver?.observe(children[children.length - 1]);
    }
  }

  onMouseOver(event: Event) {
    this.mouseOver.emit(event);
  }

  onMouseLeave(event: Event) {
    this.mouseLeave.emit(event);
  }

  scroll(y: number, event?: Event) {
    event?.stopPropagation();
    this.listRef?.nativeElement.scrollBy({
      top: y,
      behavior: 'smooth'
    });
  }

  onChangeLanguage(ref: ContextMenuComponent, event: Event) {
    event.stopPropagation();
    ref?.switchMenu(false);
  }

  lawyerId(index: any, item: LawyerSidebar) {
    return item.id;
  }

  closeSwipe(isNext: boolean) {
    if ((this.isRtl() && isNext) || (!this.isRtl() && !isNext)) this.closeForm.emit();
  }

  isRtl() {
    return this.translate.instant('global.direction') === 'rtl';
  }
}
