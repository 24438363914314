import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { AnswerLawyer, OrderLawyer, RfqLawyer, ServiceLawyer } from '@app/shared/models';
import { Lawyer, LawyerDetails } from '@app/shared/models/lawyer.model';
import { Sections } from '@app/shared/types';
import { getSectionLink } from '@app/shared/utils';
import { SharedModule } from '@shared/shared.module';
import { LawyerAvatarComponent } from '../lawyer-avatar/lawyer-avatar.component';

@Component({
  selector: 'ln-lawyer-info-short',
  templateUrl: './lawyer-info-short.component.html',
  styleUrls: ['./lawyer-info-short.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, LawyerAvatarComponent]
})
export class LawyerInfoShortComponent {
  @Input() lawyer?: Lawyer | LawyerDetails | ServiceLawyer | OrderLawyer | AnswerLawyer | RfqLawyer;
  @Input() name?: string;
  @Input() id?: number;
  @Input() alias?: string;
  @Input() image?: string;
  @Input() online?: boolean;
  @Input() hideStatus?: boolean;
  @Input() showFirm?: boolean;
  @Input() descriptionFirst?: boolean;
  @Input() description?: string;
  @HostBinding('class.truncate') @Input() truncate = true;
  @Input() small?: boolean;
  @Input() isFirm?: boolean | null;
  @Input() hideLink?: boolean;
  @Input() link?: string | null;
  @Input() showRating?: boolean;

  getSectionLink = getSectionLink;
  Sections = Sections;

  get lawyerId() {
    return this.id || (this?.lawyer && 'id' in this.lawyer ? this?.lawyer?.id : null);
  }

  get lawyerAlias() {
    return this.alias || this.lawyer?.alias || '';
  }

  get lawyerOnline() {
    return this.online || (this?.lawyer && 'online' in this.lawyer ? this?.lawyer?.online : undefined);
  }

  get lawyerAvatar(): string | undefined {
    return (
      this.image ||
      (typeof this.lawyer?.avatar === 'string'
        ? (this.lawyer?.avatar as string)
        : 'url' in (this.lawyer?.avatar || {})
          ? this.lawyer?.avatar?.url
          : undefined)
    );
  }

  get lawyerFirm() {
    return this.lawyer && 'firm' in this.lawyer ? this.lawyer.firm?.name : '';
  }

  get premium() {
    return this.lawyer && 'premiumMember' in this.lawyer ? this.lawyer?.premiumMember : false;
  }

  get rating() {
    return this.lawyer && 'rating' in this.lawyer ? this.lawyer?.rating.avg : '';
  }

  constructor() {}
}
