@use 'utils' as *;
@use 'direction' as *;

.context-menu {
  cursor: pointer;
  position: relative;

  --offset: 30px;
  --top: calc(100% - 20px);

  &__icon {
    width: 25px;
    height: 25px;
    color: var(--ln-color-text-gray);
    display: flex;
    justify-content: center;
  }

  &__overlay {
    transition: transform 0.3s;

    @include overlay(var(--top), var(--offset));

    &-end {
      padding-top: 8px;
      padding-bottom: 8px;

      transform-origin: left top !important;
      right: initial !important;
      left: var(--offset) !important;

      @include rtl {
        transform-origin: right top !important;
        right: var(--offset) !important;
        left: initial !important;
      }
    }
  }

  &__item {
    cursor: pointer;
    min-width: 170px;

    &:not(&-div) {
      padding: 8px 24px;
    }

    &-focused:not(&-div, &-disabled) {
      background: var(--ln-color-border-gray);
      color: var(--ln-color-primary-light);
    }

    &-div {
      margin-top: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid var(--ln-color-text-gray-20);
    }

    &-disabled {
      color: var(--ln-color-text-gray);
      cursor: initial;
    }
  }
}
