@use 'vars' as *;
@use 'direction' as *;

@mixin overlay($top: 0, $right: 0, $left: initial, $upside-down: false, $direction: 'start', $context: false) {
  @include rtl-value(right, $right, $left, $context);
  @include rtl-value(left, $left, $right, $context);

  background: var(--ln-color-background);
  box-shadow: 0 0 60px rgba(var(--ln-color-primary-rgb), 0.2);
  border-radius: 12px;
  color: var(--ln-color-primary);
  position: absolute;
  transition: all 0.2s;
  overflow-x: hidden;
  transform: scale(0);
  z-index: 200;

  $origin: top;

  @if $upside-down == true {
    $origin: bottom;

    bottom: $top;
  } @else {
    top: $top;
  }

  @if $direction == start {
    @include rtl-value(transform-origin, right $origin, left $origin, $context);
  } @else if $direction == end {
    @include rtl-value(transform-origin, left $origin, right $origin, $context);
  }

  &-show {
    width: auto;
    height: auto;
    transform: scale(1);
  }
}

@mixin overlay-context($top: 0, $right: 0, $left: initial, $upside-down: false, $direction: 'start') {
  @include overlay($top, $right, $left, $upside-down, $direction, true);
}

@mixin block-scroll {
  position: fixed;
  right: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
}

.block-scroll {
  @include block-scroll;
}

@mixin div-gradient($top: 0, $colorFrom: var(--ln-color-text-gray), $context: false) {
  &::after {
    @include rtl-prop(left, right, 0, $context);

    content: '';
    position: absolute;
    top: $top;
    display: block;
    height: 1px;
    width: 100%;
    background: linear-gradient(45deg, $colorFrom 0%, var(--ln-color-background) 100%);
    opacity: 0.24;

    @include rtl($context) {
      background: linear-gradient(-175deg, $colorFrom 0%, var(--ln-color-background) 100%);
    }
  }
}

@mixin div-gradient-context($top: 0, $colorFrom: var(--ln-color-text-gray)) {
  @include div-gradient($top, $colorFrom, true);
}

@mixin underline($width: 24px, $height: 4px, $clr: var(--ln-color-background)) {
  &::before {
    content: '';
    width: $width;
    height: $height;
    background: $clr;

    @include margin-end(8px);
    @include margin-end(8px, false);
  }
}

@mixin aside($start: true, $width: #{$menu-width}) {
  @if $start {
    @include rtl-value(transform, translateX(calc(100% - (100% - $width))), translateX(-#{$width}), false);
  } @else {
    @include rtl-value(transform, translateX(calc(-100% + (100% - $width))), translateX($width), false);
  }

  @media screen and (max-width: $mobile) {
    @if $start {
      @include rtl-value(
        transform,
        translateX(calc(100% - $offset-mobile)),
        translateX(calc(-100% + $offset-mobile)),
        false
      );
    } @else {
      @include rtl-value(
        transform,
        translateX(calc(-100% + $offset-mobile)),
        translateX(calc(100% - $offset-mobile)),
        false
      );
    }
  }
}
