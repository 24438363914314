import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ContextMenuItem } from '@app/shared/types';
import { isOwnElement } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, filter, ReplaySubject, takeUntil } from 'rxjs';

@Component({
    selector: 'ln-context-menu',
    templateUrl: './context-menu.component.html',
    styleUrls: ['./context-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ContextMenuComponent implements OnDestroy, OnInit {
  isShowMenu: boolean | null = null;
  isShowMenu$ = new BehaviorSubject(false);
  focusIdx = -1;
  top = 0;
  offset = 0;
  ownerElement: HTMLElement | null = null;
  isVisible = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  observer?: ResizeObserver;

  @ViewChild('menuRef') menuRef?: ElementRef;
  @ViewChild('rootRef') rootRef?: ElementRef;

  @Input() items?: ContextMenuItem[] | null;
  @Input() locales: string = '';
  @Input() showEnd = false;
  @Input() triggerHover = false;
  @Input() maxHeight: string | null = null;
  @Input() ownerSelector?: string | null = null;

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() selected = new EventEmitter();

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: any,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isShowMenu$
      .pipe(
        takeUntil(this.destroyed$),
        filter((isShowed) => isShowed)
      )
      .subscribe(() => {
        if (isPlatformBrowser(this.platformId) && this.ownerSelector) {
          setTimeout(() => {
            if (this.menuRef?.nativeElement) {
              this.ownerElement = this.doc.querySelector(this.ownerSelector);
              this.ownerElement?.appendChild(this.menuRef.nativeElement);
              if (this.ownerElement && !this.observer) {
                this.observer = new ResizeObserver((entries) => {
                  this.calcPosition();
                });
                this.observer.observe(this.ownerElement);
              }
            }
            this.calcPosition();
          }, 10);
        }
      });
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId) && this.ownerSelector) {
      if (this.menuRef?.nativeElement) {
        this.ownerElement?.removeChild(this.menuRef.nativeElement);
        if (this.observer && this.ownerElement) this.observer?.unobserve(this.ownerElement);
      }
    }
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  calcPosition() {
    if (this.rootRef?.nativeElement && this.menuRef?.nativeElement && this.ownerElement) {
      const rectRoot = this.rootRef.nativeElement.getBoundingClientRect();
      const rect = this.menuRef?.nativeElement.getBoundingClientRect();
      const rectOwner = this.ownerElement?.getBoundingClientRect();
      const scrollT =
        !this.ownerElement || this.ownerSelector === 'body'
          ? Math.max(this.doc.body.scrollTop, this.doc.documentElement.scrollTop)
          : this.ownerElement.scrollTop;

      const heightW = document.documentElement.clientHeight;
      let top = scrollT + (rectRoot.top - rectOwner.top);
      if (rectRoot.top + rect.height > heightW) top = scrollT + rectRoot.top - rect.height;
      this.top = top;
      if (this.translate.instant('global.direction') === 'rtl') this.offset = rectRoot.right + 8;
      else this.offset = this.doc.body.clientWidth - rectRoot.left + 8;
      setTimeout(() => {
        this.isVisible = true;
        this.cdr.detectChanges();
      }, 10);
    }
  }
  switchMenu(isHover: boolean, event?: Event) {
    if (this.triggerHover !== isHover) return;

    event?.stopPropagation();

    if (!this.isShowMenu && this.ownerSelector) this.calcPosition();
    this.isShowMenu = !this.isShowMenu;
    this.isShowMenu$.next(this.isShowMenu);
    this.isShowMenu ? this.opened.emit() : this.closed.emit();
    this.cdr.detectChanges();
  }

  closeMenu(isHover: boolean) {
    if (this.triggerHover !== isHover || !this.isShowMenu) return;
    this.isShowMenu = false;
    this.isShowMenu$.next(this.isShowMenu);
    this.isVisible = false;
    this.closed.emit();
  }

  clickOut(event: Event) {
    if (
      isOwnElement(event.target, this.rootRef?.nativeElement) ||
      isOwnElement(event.target, this.menuRef?.nativeElement)
    )
      return;
    this.closeMenu(this.triggerHover);
  }

  onItem(item: ContextMenuItem, event: Event) {
    event.stopPropagation();
    if (item.action) {
      this.closeMenu(this.triggerHover);
      this.selected.emit(item);
      item.action(item);
    }
  }
}
