@use 'typography' as *;
@use 'direction' as *;
@use 'vars' as *;

ln-lawyer-info-short {
  margin-bottom: -2px;

  @include rtl-prop(margin-left, margin-right, -8px, false);

  &.truncate {
    width: var(--truncate-width, 100%);
    white-space: nowrap;
    overflow: hidden;

    .lawyer-info-short {
      &__info {
        flex: 1;
        overflow: hidden;
      }

      &__info-desc,
      &__info-name > * {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.lawyer-info-short {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 2px;

  --mrg: 24px;

  @include rtl-prop(padding-left, padding-right, 8px, false);

  &:not(.small) &__ava {
    width: 43px;
    height: 43px;
  }

  &.small &__ava {
    width: 35px;
    height: 35px;

    --mrg: 8px;
  }

  &__ava {
    margin: 0;

    @media screen and (max-width: $laptop) {
      --mrg: 8px;
    }

    @include rtl-prop(margin-right, margin-left, var(--mrg), false);
  }

  &__info {
    display: var(--lawyer-info-display, flex);

    &:not(&-reverse) {
      flex-direction: column;
    }

    &-reverse {
      flex-direction: column-reverse;
    }

    &-name {
      @include label-text;

      color: var(--ln-color-primary);
      display: flex;
      align-items: center;
    }

    &-desc {
      @include common-text;

      font-weight: 500;
      color: var(--ln-color-text-gray-dark);

      &--rating {
        color: var(--ln-color-primary);

        & > ln-icon {
          color: var(--ln-color-secondary);
        }
      }
    }
  }

  &.small &__info-name {
    @include common-text;
  }
}
