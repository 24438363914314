<div
  class="lawyer-list"
  ln-click-out
  (clickOut)="closeForm.emit()"
  ln-swipe
  (previous)="closeSwipe(false)"
  (next)="closeSwipe(true)"
  (mouseleave)="onMouseLeave($event)"
>
  <div class="lawyer-list__head hide-laptop">
    <div>
      <div class="lawyer-list__head-title">{{ 'components.lawyerList.title' | translate }}</div>
      <!-- <div class="lawyer-list__head-desc">UAE, Dubai • <span>Change</span></div> -->
    </div>
    <div class="lawyer-list__settings top">
      <ln-context-menu #languagesRefMob [items]="menuLanguagesItems" maxHeight="300px">
        <ln-icon
          name="language"
          width="24px"
          height="24px"
          (click)="onChangeLanguage(languagesRefMob, $event)"
        ></ln-icon>
      </ln-context-menu>
      <span class="lawyer-list__settings-code">{{ languageCode }}</span>
    </div>
  </div>
  <div class="lawyer-list__head laptop" (mouseover)="onMouseOver($event)">
    <div class="lawyer-list__head-icon">
      <ln-icon name="chat" width="24px" height="24px"></ln-icon>
    </div>
    <div class="lawyer-list__head-title">
      {{ 'components.lawyerList.title' | translate }}
    </div>
    <div class="lawyer-list__arrow" *ngIf="showTopArrow" (click)="scroll(-HEIGHT_ITEM, $event)">
      <ln-icon name="arrow-down" width="12px" height="12px"></ln-icon>
    </div>
  </div>
  <div class="lawyer-list__body" #listRef (mouseover)="onMouseOver($event)">
    <div class="lawyer-list__section">
      <hr />
      <span class="lawyer-list__section-title">{{
        'components.lawyerList.onlineTitle' | translate: { count: online.length || 0 }
      }}</span>
    </div>
    <ln-lawyer-list-item
      class="lawyer-list__item"
      *ngFor="let lawyer of online; trackBy: lawyerId"
      [lawyer]="lawyer"
      [expanded]="expanded"
      (closeMenu)="closeForm.emit()"
    ></ln-lawyer-list-item>
    <div class="lawyer-list__section">
      <hr />
      <span class="lawyer-list__section-title">{{
        'components.lawyerList.' + (expanded ? 'offlineTitle' : 'offlineTitleCollapsed') | translate
      }}</span>
    </div>
    <ln-lawyer-list-item
      class="lawyer-list__item"
      *ngFor="let lawyer of offline; trackBy: lawyerId"
      [lawyer]="lawyer"
      [expanded]="expanded"
      (closeMenu)="closeForm.emit()"
    ></ln-lawyer-list-item>
  </div>
  <div class="lawyer-list__footer">
    <div class="lawyer-list__arrow rotate" *ngIf="showBottomArrow" (click)="scroll(HEIGHT_ITEM, $event)">
      <ln-icon name="arrow-down" width="12px" height="12px"></ln-icon>
    </div>
    <div class="lawyer-list__settings bottom">
      <ln-context-menu #languagesRef [items]="menuLanguagesItems" ownerSelector="body" maxHeight="250px">
        <ln-icon name="language" width="24px" height="24px" (click)="onChangeLanguage(languagesRef, $event)"></ln-icon>
      </ln-context-menu>
      <span class="lawyer-list__settings-code">{{ languageCode }}</span>
    </div>
  </div>
</div>
