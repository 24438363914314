<ln-modal #modalRef header="{{ 'components.lawyerCard.meet.title' | translate }}" (closed)="closed.emit()">
  <ng-container body>
    <div class="lawyer-meet">
      @if (isFirm) {
        <p>
          {{ 'components.lawyerCard.meet.firm1' | translate }}
        </p>
        <p class="lawyer-meet__bold">{{ name }}</p>
        <p>
          {{ 'components.lawyerCard.meet.firm2' | translate }}
        </p>
        <p class="lawyer-meet__contact">
          <ng-container [ngTemplateOutlet]="phonesRef"></ng-container>
        </p>
      } @else {
        <p>
          {{ 'components.lawyerCard.meet.bookWith' | translate }}
        </p>
        <p class="lawyer-meet__bold">{{ name }}</p>
        <p>
          {{ 'components.lawyerCard.meet.callOffice' | translate }}
        </p>
        <p class="lawyer-meet__bold">{{ firmName }}</p>
        <p>
          {{ 'components.lawyerCard.meet.byPhone' | translate }}
        </p>
        <p class="lawyer-meet__contact">
          <ng-container [ngTemplateOutlet]="phonesRef"></ng-container>
        </p>
      }
    </div>
  </ng-container>
  <div footer class="lawyer-meet__footer">
    <ln-icon name="exclamation" width="18px" height="18px"></ln-icon>{{ 'components.lawyerCard.meet.note' | translate }}
  </div>
</ln-modal>

<ng-template #phonesRef>
  @for (item of phones; track $index) {
    <a [attr.href]="'tel:' + item.phone" class="secondary-link"
      ><span dir="auto">{{ item.phone }}</span
      >&nbsp;
      @if (item.comment) {
        <span class="lawyer-meet__phone-comment">{{ item.comment }}</span>
      }</a
    ><br />
  }
</ng-template>
