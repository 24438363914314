@use 'typography' as *;
@use 'direction' as *;

.lawyer-meet {
  text-align: center;

  &__bold {
    @include label-text;
  }

  &__contact {
    @include title-text;
  }

  &__footer {
    display: flex;
    color: var(--ln-color-text-gray-dark);

    ln-icon {
      @include margin-end(5px, false);
    }
  }

  &__phone-comment {
    @include label-text;

    color: var(--ln-color-primary);
  }
}
