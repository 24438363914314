<div class="lawyer-info-short" [class]="{ small }">
  <ln-lawyer-avatar
    [online]="lawyerOnline"
    [id]="lawyerId || 0"
    [image]="lawyerAvatar"
    [hideStatus]="hideStatus"
    [premium]="premium"
    class="lawyer-info-short__ava"
    [class]="small ? 'lawyer-info-short__ava-small' : 'lawyer-info-short__ava-normal'"
  ></ln-lawyer-avatar>
  <div class="lawyer-info-short__info" [class]="{ 'lawyer-info-short__info-reverse': descriptionFirst }">
    <div class="lawyer-info-short__info-name">
      <a
        *ngIf="lawyerId && !hideLink"
        [routerLink]="
          link || getSectionLink(isFirm ? Sections.LawFirms : Sections.Lawyers, lawyerId, lawyerAlias) | localize
        "
        >{{ name || lawyer?.name }}</a
      >
      <span *ngIf="lawyerId && hideLink">{{ name || lawyer?.name }}</span>
      <ng-content select="[titlePostfix]"></ng-content>
    </div>
    <span *ngIf="!lawyerId" class="lawyer-info-short__info-name">{{ name || lawyer?.name }}</span>

    <div #descrRef class="lawyer-info-short__info-desc"><ng-content></ng-content></div>
    <ng-container *ngIf="!descrRef.hasChildNodes()">
      <div class="lawyer-info-short__info-desc">
        @if (showRating) {
          <span class="lawyer-info-short__info-desc--rating">
            <ln-icon name="star" width="20px" height="12px"></ln-icon>
            <span>{{ rating }}</span>
            <span> • </span>
          </span>
        }
        {{ description || lawyerFirm }}
      </div></ng-container
    >
  </div>
</div>
