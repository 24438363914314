import { NgModule } from '@angular/core';
import { ContextMenuComponent } from './context-menu.component';
import { SharedModule } from '@app/shared/shared.module';
import { LawyerMeetComponent } from '@comp/lawyer';
import { ModalComponent } from '@comp/modal/modal.component';

@NgModule({
  imports: [SharedModule, LawyerMeetComponent, ModalComponent],
  declarations: [ContextMenuComponent],
  exports: [ContextMenuComponent]
})
export class ContextMenuModule {}
