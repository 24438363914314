<a
  class="lawyer-actions__button"
  *ngIf="!hideChat"
  (click)="chat.emit()"
  [routerLink]="'/ask-a-lawyer' | localize"
  [queryParams]="queryParamsActions"
>
  <ln-icon *ngIf="!hideIcons" name="chat" width="21px" height="19px"></ln-icon>
  {{ 'components.lawyerCard.chat' | translate }}
</a>
@if (hireDisabled) {
  <div class="lawyer-actions__button lawyer-actions__button-disabled">
    <ln-icon *ngIf="!hideIcons" name="hire" width="25px" height="17px"></ln-icon>
    {{ 'components.lawyerCard.hire' | translate }}
  </div>
} @else {
  <a
    class="lawyer-actions__button"
    [routerLink]="getServicesLinkWithCountry(Sections.Services, settings.countryCode || '') | localize"
    [queryParams]="servicesParams(isFirm, id)"
    (click)="hire.emit()"
  >
    <ln-icon *ngIf="!hideIcons" name="hire" width="25px" height="17px"></ln-icon>
    {{ 'components.lawyerCard.hire' | translate }}
  </a>
}

<div
  class="lawyer-actions__button"
  [class]="{ 'lawyer-actions__button-disabled': !bookAvailable }"
  (click)="bookAvailable ? openMeet() : null"
>
  <ln-icon *ngIf="!hideIcons" name="calendar-checkmark" width="20px" height="19px"></ln-icon>
  {{ 'components.lawyerCard.meet.button' | translate }}
</div>
<ln-lawyer-meet
  #meetRef
  [name]="item?.name"
  [firmName]="firm"
  [phone]="phone"
  [isFirm]="isFirm"
  *ngIf="isShowMeet"
></ln-lawyer-meet>
