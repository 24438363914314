@use 'vars' as *;
@use 'direction' as *;

@mixin h1 {
  font-weight: 700;
  font-size: 72px;
  line-height: 1.1944;

  @media screen and (max-width: $laptop) {
    font-size: 32px;
    line-height: 1.1875;
  }
}

.h1,
h1 {
  @include h1;
}

@mixin h2($includeTablet: true) {
  font-size: 36px;
  font-weight: 700;
  line-height: 1.1666;

  @if $includeTablet {
    @media screen and (max-width: $tablet) {
      font-size: 20px;
      line-height: 1.2;
    }
  }
}

.h2,
h2 {
  @include h2;
}

@mixin h3($includeTablet: true) {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2083;

  @if $includeTablet {
    @media screen and (max-width: $tablet) {
      font-size: 18px;
      line-height: 1.2222;
    }
  }
}

.h3,
h3 {
  @include h3;
}

@mixin h4 {
  font-size: 21px;
  font-weight: 400;
  line-height: 1.1904;

  @media screen and (max-width: $laptop) {
    font-size: 16px;
    line-height: 1.25;
  }
}

.h4,
h4 {
  @include h4;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

@mixin title-text {
  font-size: 21px;
  font-weight: 600;
  line-height: 1.1904;

  @media screen and (max-width: $laptop) {
    font-size: 18px;
    line-height: 1.2222;
  }
}

.title-text {
  @include title-text;
}

@mixin big-text {
  font-weight: 700;
  font-size: 48px;
  line-height: 1.2083;

  @media screen and (max-width: $laptop) {
    font-size: 32px;
    line-height: 1.1875;
  }
}

.big-text {
  @include big-text;
}

@mixin common-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285;
}

.common-text {
  @include common-text;
}

@mixin quote-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  font-style: italic;

  @media screen and (max-width: $tablet) {
    font-size: 14px;
    line-height: 1.4285;
  }
}

.quote-text {
  @include quote-text;
}

@mixin small-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.1666;
}

.small-text {
  @include small-text;
}

@mixin sub-text {
  font-size: 11px;
  font-weight: 500;
  line-height: 1.1666;
}

.sub-text {
  @include small-text;
}

@mixin highlight-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1875;

  @media screen and (max-width: $tablet) {
    font-size: 14px;
    line-height: 1.2142;
  }
}

.highlight-text {
  @include highlight-text;
}

@mixin label-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1875;
}

.label-text {
  @include label-text;
}

@mixin button-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2142;
}

.button-text {
  @include button-text;
}

.color-error {
  color: var(--ln-color-error);
}

.html-text {
  & img {
    max-width: 100%;
  }

  & a {
    color: var(--ln-color-secondary);

    &:hover {
      filter: brightness(90%);
    }
  }

  & h2,
  h3 {
    @include title-text;
  }

  & h6,
  h5,
  h4 {
    @include highlight-text;
  }

  & .inline-video {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  & .flex-row-vcentered {
    display: flex;
    align-items: center;
  }

  & .flex-halign-between {
    justify-content: space-between;
  }

  & .flex-halign-around {
    justify-content: space-around;
  }
}
