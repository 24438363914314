<div
  class="context-menu"
  (mouseenter)="switchMenu(true)"
  (mouseleave)="closeMenu(true)"
  (click)="switchMenu(false, $event)"
  ln-click-out
  (clickOut)="clickOut($event)"
  #rootRef
>
  <div #ref><ng-content></ng-content></div>
  <ng-container *ngIf="!ref.hasChildNodes()"
    ><ln-icon class="context-menu__icon" name="dots-vertical" width="4px" height="20px"></ln-icon
  ></ng-container>
  <div
    *ngIf="isShowMenu"
    class="context-menu__overlay"
    #menuRef
    [class]="{ 'context-menu__overlay-show': isShowMenu, 'context-menu__overlay-right': showEnd }"
    [style]="
      ownerSelector
        ? { '--top': top + 'px', '--offset': offset + 'px', maxHeight, visibility: isVisible ? 'visible' : 'hidden' }
        : { maxHeight }
    "
  >
    <div
      class="context-menu__item"
      *ngFor="let item of items; let idx = index"
      [class]="{
        'context-menu__item-focused': idx === focusIdx,
        'context-menu__item-div': item.name === null,
        'context-menu__item-disabled': item.disabled
      }"
      (mouseenter)="focusIdx = idx"
      (click)="!item.disabled ? onItem(item, $event) : null"
    >
      {{ item.name ? (locales ? (locales | translate)[item.name] : item.name) : '' }}
    </div>
  </div>
</div>
