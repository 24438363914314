<div class="lawyer-list-item" [class]="{ expanded }" (click)="onItem($event)">
  <ng-content></ng-content>
  <ln-lawyer-avatar
    class="lawyer-list-item__ava"
    [online]="lawyer?.online"
    [image]="lawyer?.avatar?.url"
    [premium]="lawyer?.premiumMember"
    [id]="lawyer?.id"
  ></ln-lawyer-avatar>
  <div class="lawyer-list-item__common">
    <div class="lawyer-list-item__info">
      <div class="lawyer-list-item__name">{{ lawyer?.name || '' }}</div>
      <div class="lawyer-list-item__desc">
        <span class="lawyer-list-item__desc-flag" class="fi fi-{{ lawyer?.country?.code | lowercase }}"></span>
        <ln-icon name="star" width="20px" height="12px" class="lawyer-list-item__rating-icon"></ln-icon>
        <span>{{ lawyer?.rating?.avg }}</span>
        <span class="lawyer-list-item__status">
          {{ 'components.lawyerList.active' | translate }}
          <span [style]="{ color: getLastActivityColor(lawyer?.activity) }"> {{ lawyer?.activity | humanTime }}</span>
        </span>
      </div>
    </div>
    <ln-context-menu
      #menuRef
      [items]="menuItems"
      [locales]="'components.lawyerMenu.items'"
      (opened)="onOpenedContextMenu()"
      [ownerSelector]="null"
      (selected)="closeMenu.emit()"
    ></ln-context-menu>
  </div>
</div>
@defer (on idle) {
  <ln-lawyer-meet
    *ngIf="addModalToDom"
    #modalMeet
    [name]="lawyer?.name"
    [firmName]="lawyer?.firm?.name"
    [phone]="lawyer?.phone?.number"
  ></ln-lawyer-meet>
}
