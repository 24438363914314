import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ModalComponent } from '../../modal/modal.component';
import { SharedModule } from '@shared/shared.module';

type PhoneComment = { phone: string; comment?: string };
@Component({
  selector: 'ln-lawyer-meet',
  templateUrl: './lawyer-meet.component.html',
  styleUrls: ['./lawyer-meet.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, ModalComponent]
})
export class LawyerMeetComponent {
  @ViewChild('modalRef') modalRef?: ModalComponent;

  @Input() name?: string;
  @Input() firmName?: string;
  @Input() phone?: string | PhoneComment[];
  @Input() isFirm = false;

  @Output() closed = new EventEmitter();

  public open() {
    this.modalRef?.open();
  }

  get phones() {
    return Array.isArray(this.phone) ? this.phone : [{ phone: this.phone, comment: '' }];
  }

  constructor() {}
}
