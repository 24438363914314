import {
  Component,
  Input,
  OnInit,
  ViewChild,
  HostBinding,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { LawyerSidebar } from '@app/shared/models';
import { ContextMenuItem, Sections } from '@app/shared/types';
import { getLastActivityColor, getServicesLinkWithCountry, servicesParams } from '@app/shared/utils';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { getSectionLink } from '@app/shared/utils';
import { GtagService } from '@app/shared/services/gtag.service';
import { SharedModule } from '@app/shared/shared.module';
import { ContextMenuModule } from '@app/shared/components/context-menu/context-menu.module';
import { AppSettingsService } from '@app/shared/services';
import { ContextMenuComponent } from '@app/shared/components/context-menu/context-menu.component';
import { LawyerMeetComponent, LawyerAvatarComponent } from '@comp/lawyer';

@Component({
    selector: 'ln-lawyer-list-item',
    templateUrl: './lawyer-list-item.component.html',
    styleUrls: ['./lawyer-list-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SharedModule, LawyerAvatarComponent, ContextMenuModule, LawyerMeetComponent]
})
export class LawyerListItemComponent implements OnInit {
  getLastActivityColor = getLastActivityColor;
  servicesParams = servicesParams;
  _expanded = false;

  @HostBinding('class.expanded')
  @Input()
  set expanded(value: boolean) {
    this._expanded = value;
    if (!value) this.menuRef?.closeMenu(false);
  }
  get expanded() {
    return this._expanded;
  }
  @Input() lawyer?: LawyerSidebar;

  @Output() closeMenu = new EventEmitter();

  addModalToDom = false;
  @ViewChild('modalMeet') modalMeet?: ModalComponent;
  @ViewChild('menuRef') menuRef?: ContextMenuComponent;

  menuItems: ContextMenuItem[] = [];

  constructor(
    private router: Router,
    private localize: LocalizeRouterService,
    private gtagService: GtagService,
    private settings: AppSettingsService
  ) {}

  ngOnInit() {
    this.menuItems = [
      {
        name: 'question',
        action: () => this.onPostQuestion()
      },
      { name: null, action: null },
      {
        name: 'book',
        action: () => this.onBook(),
        disabled: !this.lawyer?.book_meeting
      },
      { name: 'hire', action: () => this.onHire(), disabled: !this.lawyer?.services },
      { name: null, action: null },
      { name: 'profile', action: () => this.onProfile() }
    ];
  }

  onPostQuestion() {
    this.router.navigate([this.localize.translateRoute('/ask-a-lawyer')], {
      queryParams: { lawyer: this.lawyer?.id, name: this.lawyer?.name }
    });
  }
  onBook() {
    if (this.lawyer?.book_meeting) {
      this.modalMeet?.open();
    }
  }
  onHire() {
    this.router.navigate(
      [...this.localize.translateRoute(getServicesLinkWithCountry(Sections.Services, this.settings.countryCode || ''))],
      {
        queryParams: servicesParams(false, this.lawyer?.id)
      }
    );
  }
  onProfile() {
    this.router.navigate([
      ...this.localize.translateRoute(
        getSectionLink(Sections.Lawyers, String(this.lawyer?.id || '0'), this.lawyer?.alias || '')
      )
    ]);
  }
  onOpenedContextMenu() {
    this.addModalToDom = true;

    this.gtagService.event('Lawyer menu opening', {
      'event_category': 'Profitable Engagement'
    });
  }

  onItem(event: Event) {
    if (this.expanded) this.menuRef?.switchMenu(false);
  }
}
