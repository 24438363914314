@mixin ltr($context: true) {
  @if $context {
    @at-root :host-context([dir='ltr']) #{&} {
      @content;
    }
  } @else {
    @at-root [dir='ltr'] #{&} {
      @content;
    }
  }
}

@mixin rtl($context: true) {
  @if $context {
    @at-root :host-context([dir='rtl']) #{&} {
      @content;
    }
  } @else {
    @at-root [dir='rtl'] #{&} {
      @content;
    }
  }
}

@mixin margin-end($val, $context: true) {
  @include ltr($context) {
    margin-right: $val;
  }

  @include rtl($context) {
    margin-left: $val;
  }
}

@mixin margin-start($val, $context: true) {
  @include ltr($context) {
    margin-left: $val;
  }

  @include rtl($context) {
    margin-right: $val;
  }
}

@mixin rtl-value($property, $ltr-value, $rtl-value, $context: true) {
  @include ltr($context) {
    #{$property}: $ltr-value;
  }

  @include rtl($context) {
    #{$property}: $rtl-value;
  }
}

@mixin rtl-prop($ltr-property, $rtl-property, $value, $context: true) {
  @include ltr($context) {
    #{$ltr-property}: $value;
  }

  @include rtl($context) {
    #{$rtl-property}: $value;
  }
}
