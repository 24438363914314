/* breakpoints */
$mobile: 480px;
$tablet: 640px;
$laptop: 1024px;
$desktop: 1280px;

/* decoration */
$lawyers-width: 350px;
$lawyers-width-mobile: 400px;

$menu-width: 400px;
$offset-mobile: 52px;
$shadow-big: 0 20px 60px var(--ln-color-text-gray-20);
$shadow-big-light: 0 20px 60px var(--ln-color-text-gray-20);

/* position */
$header-height-normal: 72px;
$header-height-thin: 53px;
$container-width: 1140px;
$container-padding-mobile: 8px;
$container-padding: 15px;
