import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import {
  AnswerLawyer,
  Firm,
  FirmLawyer,
  Lawyer,
  LawyerDetails,
  OrderLawyer,
  RfqLawyer,
  ServiceLawyer
} from '@app/shared/models';
import { getSectionLink, getServicesLinkWithCountry, servicesParams } from '@app/shared/utils';
import { LawyerMeetComponent } from '@app/shared/components/lawyer/lawyer-meet/lawyer-meet.component';
import { Sections } from '@app/shared/types';
import { AppSettingsService } from '@app/shared/services';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'ln-lawyer-actions',
  templateUrl: './lawyer-actions.component.html',
  styleUrls: ['./lawyer-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, LawyerMeetComponent]
})
export class LawyerActionsComponent {
  @Input() @HostBinding('class.split') splitButtons = false;
  @HostBinding('class.lawyer-actions') parentClass = true;
  @Input() item?: Lawyer | FirmLawyer | LawyerDetails | AnswerLawyer | OrderLawyer | ServiceLawyer | RfqLawyer | Firm;
  @Input() phone?: string | { phone: string; comment?: string }[];
  @Input() hireDisabled?: boolean = true;
  @Input() firmName?: string;
  @Input() lawyerId?: number;
  @Input() hideChat = false;
  @Input() isFirm = false;
  @Input() hideIcons = false;

  servicesParams = servicesParams;
  getSectionLink = getSectionLink;
  getServicesLinkWithCountry = getServicesLinkWithCountry;
  Sections = Sections;

  @ViewChild('meetRef') meetRef?: LawyerMeetComponent;
  @Output() chat = new EventEmitter();
  @Output() hire = new EventEmitter();
  @Output() meet = new EventEmitter();

  isShowMeet = false;

  constructor(protected settings: AppSettingsService) {}

  get bookAvailable() {
    return !this.isFirm && this.item && 'book_meeting' in this.item ? this.item.book_meeting : true;
  }

  get firm(): string {
    return this.firmName ? this.firmName : this.item && 'firm' in this.item ? this.item?.firm?.name || '' : '';
  }

  get id() {
    return this.lawyerId ? this.lawyerId : this.item && 'id' in this.item ? this.item?.id : 0;
  }

  get queryParamsActions() {
    return this.isFirm ? { firm: this.id, name: this.item?.name } : { lawyer: this.id, name: this.item?.name };
  }

  openMeet() {
    this.isShowMeet = true;
    setTimeout(() => {
      this.meetRef?.open();
    }, 50);
  }
}
